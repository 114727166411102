import Header from '../../components/header'
import Footer from '../../components/footer'

const About = () => {
  return (
    <div>
      <Header />
      <main className="flex-shrink-0">
        <div className="container">
          <h3 className="mt-5">About</h3>
          <p></p>
          <p className="lead"></p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default About;
