import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext';
import SignIn from './routes/auth/signIn';
import SignUp from './routes/auth/signUp';
import VerifyCode from './routes/auth/verify';
import RequestCode from './routes/auth/requestCode';
import ForgotPassword from './routes/auth/forgotPassword';
import ChangePassword from './routes/auth/changePassword';
import About from './routes/pub/about';
import Contact from './routes/pub/contact';
import Terms from './routes/pub/terms';
import Privacy from './routes/pub/privacy';
import ViewUserDetails from './routes/user/viewUserDetails';
import Home from './routes/home';
import HomeIn from './routes/homeIn';

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/contactus" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
);

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/" element={<HomeIn />} />
      <Route path="/view-contact-details/:id" element={<ViewUserDetails />} />
    </Routes>
  </Router>
);

const App: React.FunctionComponent = () => (
  <AuthProvider>
    <AuthIsSignedIn>
      <MainRoute />
    </AuthIsSignedIn>
    <AuthIsNotSignedIn>
      <SignInRoute />
    </AuthIsNotSignedIn>
  </AuthProvider>
);

export default App;
