import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import HeaderIn from '../../components/headerIn'
import FooterIn from '../../components/footerIn'
import './changePassword.css';

const ChangePassword = () => {

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const handleUpdatePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const target = event.target as typeof event.target & {
            oldPassword: { value: string };
            password: { value: string };
            passwordConfirm: { value: string };
      };
      const oldPassword = target.oldPassword.value; // typechecks!
      const password = target.password.value; // typechecks!
      const passwordConfirm = target.passwordConfirm.value; // typechecks!

      // check password match
      if(password.toLowerCase() !== passwordConfirm.toLowerCase())
      {
        setError("Password does not match");
        return;
      }

      await authContext.changePassword(oldPassword, password)
      await authContext.signOut()

      navigate('/signin')
    } catch (err: any) {
      setError(err.message);
    }
  }

  return (
    <div>
      <HeaderIn />
      <main className="form-updatepassword w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal"></h1>
        <h1 className="h3 mb-3 fw-normal">Change Password</h1>

        <form onSubmit={handleUpdatePasswordSubmit}>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingOldPassword" name="oldPassword" placeholder="oldPassword" />
            <label htmlFor="floatingOldPassword">Old Password</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" name="password" placeholder="Password" />
            <label htmlFor="floatingPassword">New Password</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPasswordConfirm" name="passwordConfirm" placeholder="Confirm Password" />
            <label htmlFor="floatingPasswordConfirm">Confirm Password</label>
          </div>

          <div className="mb-3">
            <label>Mininum 8 in length, at least 1 number and 1 upper case. </label>
            <label className="text-danger">
              {error}
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Change Password</button>
        </form>
      </main>
      <FooterIn />
    </div>
  );
}

export default ChangePassword;
