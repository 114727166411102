import React, {useContext} from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';

const HeaderIn: React.FunctionComponent = () => {

  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  const signOut = () => {
    auth.signOut();
    navigate('/')
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/"><span className="text-warning">Home</span>Buyer<span className="text-warning">Worksheet</span></NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Link</a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled">Disabled</a>
              </li>
            </ul>
            <div className="text-end">
              <button type="button" onClick={signOut} className="btn btn-outline-light me-2">Sign Out</button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default HeaderIn;
