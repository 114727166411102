import React from "react";
import HeaderIn from '../../components/headerIn'
import FooterIn from '../../components/footerIn'
import { useParams } from "react-router-dom";

const ViewUserDetails = () => {

  let params = useParams();

  return (
    <div>
      <HeaderIn />
      <main className="flex-shrink-0">
        <div className="container">
          <h1 className="mt-5">Details for {params.id}</h1>
          <p></p>
          <p className="lead">stuff for user {params.id}</p>
        </div>
      </main>
      <FooterIn />

    </div>
  );
};

export default ViewUserDetails;
