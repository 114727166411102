import HeaderIn from '../components/headerIn'
import FooterIn from '../components/footerIn'
import { NavLink } from "react-router-dom";

const HomeIn = () => {
  return (
    <div>
      <HeaderIn />
      <main className="flex-shrink-0">
        <div className="container">
          <h1 className="mt-5">Dashboard</h1>
          <p></p>
          <p className="lead">Dashboard with list of events</p>
          <p><NavLink to="/view-contact-details/1" > Detail 1 </NavLink></p>
          <p><NavLink to="/view-contact-details/2"> Detail 2 </NavLink></p>
        </div>
      </main>
      <FooterIn />
    </div>
  );
}

export default HomeIn;
