import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './requestCode.css';

const RequestCode = () => {

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const handleRequestCodeSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const target = event.target as typeof event.target & {
            email: { value: string };
      };
      const email = target.email.value; // typechecks!
      await authContext.sendCode(email);
      navigate('/forgotpassword')
    } catch (err: any) {
      setError("Unknown User");
    }
  }

  return (
    <div>
      <Header />
      <main className="form-requestcode w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal"></h1>
        <h1 className="h3 mb-3 fw-normal">Request Password Reset Code</h1>

        <form onSubmit={handleRequestCodeSubmit}>
          <div className="form-floating">
            <input type="email" required className="form-control" id="floatingEmail" name="email" placeholder="Recovery Email" />
            <label htmlFor="floatingEmail">Email address</label>
          </div>

          <div className="text-danger mb-3">
            <label>
              {error}
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Send Code</button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default RequestCode;
