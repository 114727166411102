import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './verify.css';

const Verify = () => {

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const handleVerifySubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const target = event.target as typeof event.target & {
            email: { value: string };
            code: { value: string };
      };
      const email = target.email.value; // typechecks!
      const code = target.code.value; // typechecks!
      await authContext.verifyCode(email, code);
      navigate('/signin')
    } catch (err: any) {
      setError("Invalid Email or Code");
    }
  }

  return (
    <div>
      <Header />
      <main className="form-verify w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal"></h1>
        <h1 className="h3 mb-3 fw-normal">Verify Account</h1>

        <form onSubmit={handleVerifySubmit}>
          <div className="form-floating">
            <input type="email" required className="form-control" id="floatingEmail" name="email" placeholder="Email" />
            <label htmlFor="floatingEmail">Email address</label>
          </div>
          <div className="form-floating">
            <input type="code" required className="form-control" id="floatingCode" name="code" placeholder="Verification Code" />
            <label htmlFor="floatingCode">Verification Code</label>
          </div>

          <div className="text-danger mb-3">
            <label>
              {error}
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Confirm</button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default Verify;
