import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './forgotPassword.css';

const ForgotPassword = () => {

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const handleResetPasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const target = event.target as typeof event.target & {
            code: { value: string };
            email: { value: string };
            password: { value: string };
            passwordConfirm: { value: string };
      };
      const code = target.code.value; // typechecks!
      const email = target.email.value; // typechecks!
      const password = target.password.value; // typechecks!
      const passwordConfirm = target.passwordConfirm.value; // typechecks!

      // check password match
      if(password.toLowerCase() !== passwordConfirm.toLowerCase())
      {
        setError("Password does not match");
        return;
      }

      await authContext.forgotPassword(email, code, password)
      navigate('/signin')
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('/verify')
      } else {
        setError(err.message);
      }
    }
  }

  return (
    <div>
      <Header />
      <main className="form-resetpassword w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal"></h1>
        <h1 className="h3 mb-3 fw-normal">Forgot Password</h1>

        <form onSubmit={handleResetPasswordSubmit}>
          <div className="form-floating">
            <input type="text" className="form-control" id="floatingCode" name="code" placeholder="code" />
            <label htmlFor="floatingCode">Verification Code</label>
          </div>
          <div className="form-floating">
            <input type="email" className="form-control" id="floatingEmail" name="email" placeholder="name@example.com" />
            <label htmlFor="floatingEmail">Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" name="password" placeholder="Password" />
            <label htmlFor="floatingPassword">Password</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPasswordConfirm" name="passwordConfirm" placeholder="Confirm Password" />
            <label htmlFor="floatingPasswordConfirm">Confirm Password</label>
          </div>

          <div className="mb-3">
            <label>Mininum 8 in length, at least 1 number and 1 upper case. </label>
            <label className="text-danger">
              {error}
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Change Password</button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
