import React from 'react';
import { NavLink } from 'react-router-dom';

const FooterIn : React.FunctionComponent = () => {

  return (
    <footer className="footer mt-auto py-3 bg-light">
      <div />
    </footer>
  );
}

export default FooterIn;
