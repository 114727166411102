import React, { useState, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './signIn.css';

const SignIn = () => {

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const handleSignInSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const target = event.target as typeof event.target & {
            username: { value: string };
            password: { value: string };
      };
      const username = target.username.value; // typechecks!
      const password = target.password.value; // typechecks!
      await authContext.signInWithEmail(username, password)
      navigate('/')
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('/verify')
      } else {
        setError(err.message);
      }
    }
  }

  return (
    <div>
      <Header />
      <main className="form-signin w-100 m-auto">
        <h1 className="h3 mb-3 fw-normal"></h1>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <form onSubmit={handleSignInSubmit}>
          <div className="form-floating">
            <input type="email" required className="form-control" id="floatingUsername" name="username" placeholder="name@example.com" />
            <label htmlFor="floatingUsername">Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" required className="form-control" id="floatingPassword" name="password" placeholder="Password" />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <div className="text-danger mb-3">
            <label>
              {error}
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
          <div className="mb-3">
            <NavLink className="link-secondary no-underline" to="/requestcode">Forgot Password?</NavLink>
          </div>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default SignIn;
