import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer : React.FunctionComponent = () => {

  return (
    <footer className="footer mt-auto py-3 bg-light">
      <div className="container">
        <div className="d-flex justify-content-between">
          <div>
            <span className="text-muted">&copy; {new Date().getFullYear()} <NavLink to="/" className="link-secondary no-underline">Collaboration Cloudtech LLC</NavLink></span>
          </div>
          <div>
            <span className="text-muted">
              <NavLink to="/about" className="link-secondary no-underline">About</NavLink> | <NavLink to="/contactus" className="link-secondary no-underline">Contact Us</NavLink> | <NavLink to="/terms" className="link-secondary no-underline">Terms</NavLink> | <NavLink to="/privacy" className="link-secondary no-underline">Privacy</NavLink>
            </span>
          </div>
        </div> 
      </div>
    </footer>
  );
}

export default Footer;
