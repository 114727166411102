import Header from '../components/header'
import Footer from '../components/footer'
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Header />
      <main className="flex-shrink-0">
        <div className="container">
          <h1 className="mt-5">Home Buyer Worksheet</h1>
          <p></p>
          <p className="lead">AI-powered tools to guide you through your home buying process.</p>
          <ul>
            <li>Analyze and manage your cost</li>
            <li>Keep track of important tasks</li>
            <li>Compare your options</li>
            <li>Find agent and service providers in your community</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
